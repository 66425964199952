/** @format */
const homeRouter = [
  {
    path: "/",
    name: "Main",
    component: () => import("@/views/Home/Main"),
  },
  {
    path: "interior-design",
    name: "InteriorDesign",
    component: () => import("@/views/Home/InteriorDesign"),
    meta: { requiresAuth: true },
  },
  {
    path: "interior-design/page-:page",
    name: "InteriorDesignPage",
    component: () => import("@/views/Home/InteriorDesign"),
    meta: { requiresAuth: true },
  },
  {
    path: "interior-design-detail/:id",
    name: "InteriorDesignDetail",
    component: () => import("@/views/Home/InteriorDesignDetail"),
    meta: { requiresAuth: true },
  },
  {
    path: "interior-design-room-detail/design/:id/room/:roomId",
    name: "InteriorDesignRoomDetail",
    component: () => import("@/views/Home/InteriorDesignRoomDetail"),
    meta: { requiresAuth: true },
  },
  {
    path: "project-detail/:id",
    name: "ProjectDetail",
    component: () => import("@/views/Home/ProjectDetail"),
  },
  {
    path: "about-us",
    name: "AboutUs",
    component: () => import("@/views/Home/AboutUs"),
  },
  {
    path: "press",
    name: "Press",
    component: () => import("@/views/Home/Press"),
  },
  {
    path: "affiliate",
    name: "Affiliate",
    component: () => import("@/views/Home/Affiliate"),
  },
  {
    path: "terms",
    name: "Terms",
    component: () => import("@/views/Home/Terms"),
  },
  {
    path: "term-detail/:category",
    name: "TermDetail",
    component: () => import("@/views/Home/TermDetail"),
  },
  {
    path: "banner-detail/:id",
    name: "BannerDetail",
    component: () => import("@/views/Home/BannerDetail"),
  },
  {
    path: "banner-shop-detail",
    name: "BannerShopDetail",
    component: () => import("@/views/Home/BannerShopDetail"),
  },
  {
    path: "shop-manager/:type",
    name: "ShopManager",
    component: () => import("@/views/Home/ShopManager"),
    meta: { requiresCommerceParner: true },
  },
  // {
  //   path: "shop-manager/list-item",
  //   name: "ListItem",
  //   component: () => import("@/views/Home/components/ItemList/ItemList"),
  // },
  {
    path: "shop-manager/order-status",
    name: "OrderStatus",
    component: () => import("@/views/Home/components/OrderStatus"),
  },
  {
    path: "shop-manager/redbill-detail/:id",
    name: "RedbillOrderInformation",
    component: () => import("@/views/Home/ShopManager"),
  },
  {
    path: "shop-manager/upload-item/:id",
    name: "UploadItem",
    component: () => import("@/views/Home/ShopManager"),
  },
  {
    path: "shop-manager/detail-content/:id",
    name: "DashboardNoticeDetail",
    component: () => import("@/views/Home/ShopManager"),
  },

  // {
  //   path: "shop-manager/promotion/:id",
  //   name: "Promotion",
  //   component: () => import("@/views/Home/ShopManager"),
  // },
  {
    path: "shop-manager/add-item-notice/:id",
    name: "AddNoticeItem",
    component: () => import("@/views/Home/ShopManager"),
  },
];
export default homeRouter;
